<template>
  <Layout class="GameLibrary">
    <el-input
      v-model="uid"
      type="number"
      style="width: 220px; margin-right: 10px;"
      placeholder="请输入UID"
      clearable
    />
    <el-button
      type="primary"
      style="margin-right: 10px;"
      @click="addItem"
    >
      添加
    </el-button>
    <UploadExcel
      text="导入用户"
      @uploaded="onUploaded"
    />
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        type="index"
        label="序号"
        width="80"
      />
      <el-table-column
        prop="UID"
        label="UID"
        width="300"
      />
      <el-table-column
        label="操作"
      >
        <template v-slot="d">
          <el-button
            type="danger"
            @click="delRow(d)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
    >
      <el-form-item prop="content">
        <el-input
          v-model="ruleForm.content"
          class="textarea"
          type="textarea"
          :rows="3"
          placeholder="请输入内容"
        />
      </el-form-item>
    </el-form>
    <div class="but-box">
      <el-button
        v-loading="butLoading"
        type="primary"
        @click="sendbody"
      >
        推送
      </el-button>
    </div>
  </Layout>
</template>

<script>
import { batchSendMessage } from '@/api/Userfeedback.js'
import UploadExcel from '@/components/UploadExcel/uploadExcel'
export default {
  name: 'Messagepush',
  components: {
    UploadExcel
  },
  data () {
    return {
      tableData: [],
      ruleForm: {
        content: ''
      },
      rules: {
        content: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ]
      },
      butLoading: false,
      uid: ''
    }
  },
  methods: {
    addItem () {
      const uid = this.uid
      console.log(uid)
      if (!uid) return this.$message.warning('请输入UID')
      const has = this.tableData.some(i => i.UID === this.uid)
      if (has) return this.$message.warning('UID已存在')
      this.tableData.push({ UID: this.uid })
      this.uid = ''
    },
    onUploaded (data) {
      console.log(data)
      const header = data.header
      const key = header.find(i => i.toLocaleUpperCase().trim() === 'UID')
      if (!key) return this.$message.error('表头没有UID')
      const list = data.list.map(i => {
        return {
          UID: typeof i[key] === 'number' ? i[key] : i[key].trim()
        }
      })
      const tableData = [...this.tableData, ...list]
      // const repetition = []
      this.tableData = tableData.filter((v, index) => {
        // 去重
        const index1 = tableData.findIndex(i => i.UID === v.UID)
        if (index1 === index) {
          return true
        } else {
          // repetition.push(index + ',' + index1)
        }
      })
    },
    delRow (row) {
      console.log(row)
      this.tableData.splice(row.$index, 1)
    },
    async sendbody () {
      if (this.tableData.length === 0) {
        return this.$message.warning('请导入用户')
      }
      await this.$refs.ruleForm.validate()
      await this.$confirm('确定发送消息吗?', '消息发送', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      this.butLoading = true
      let res
      try {
        res = await batchSendMessage({
          userIds: this.tableData.map(i => i.UID),
          body: this.ruleForm.content
        })
        console.log(res)
        this.$message.success('推送成功')
      } catch (e) {
        console.log(e)
      }
      this.butLoading = false
    }
  }
}
</script>

<style scoped lang="less">
.GameLibrary {
  .textarea {
    margin-top: 10px;
  }
  .but-box {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
